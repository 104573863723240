<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import '@raidboxes/storybook/style.css';
import type { MetaObject } from '@nuxt/schema';

const authStore = useAuthStore();
const userStore = useUserStore();
const languages = usePreferredLanguages();
const { localeCodes, defaultLocale, locale } = useI18n();

if (authStore.isLoggedIn) {
  userStore.setUserId(authStore.getUserId as string);
  await userStore.fetchUserData();
  let languageOfUser = userStore.userObject.language;
  if (languageOfUser === '' || languageOfUser === undefined || languageOfUser === null) {
    languageOfUser = languages.value[0].substring(0, 2);
  }
  if (!localeCodes.value.includes(languageOfUser)) {
    languageOfUser = defaultLocale;
  }
  useI18nSetLocale(languageOfUser);
}

const headMeta = computed<MetaObject>(() => {
  return {
    htmlAttrs: {
      lang: locale.value
    },
  }
});

useHead(headMeta);
</script>
